import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/dashboard-questy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    schemat: file(relativePath: { eq: "projektowanie-procesu-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    aktywnosci: file(relativePath: { eq: "dashboard-aktywnosci.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     aktywnoscihandlowcow: file(relativePath: { eq: "dashboard-aktywnosci-handlowcow.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    szanse: file(relativePath: { eq: "dashboard-szanse-sprzedazy.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
      sprzedaz: file(relativePath: { eq: "dashboard-sprzedaz.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
     zdarzenia: file(relativePath: { eq: "dashboard-zdarzenia.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const DashboardManagera = ({ data }) => {
  return (
    <Artykul
      title="Dashboard managera – jeden rzut oka, by poznać sytuację całej firmy"
      articleImage={obrazekArtykulu}
      articleImageAlt="Dashboard managera – jeden rzut oka, by poznać sytuację całej firmy"
      metaTitle="Dashboard managera"
      metaDescription="Jeden rzut oka, by poznać sytuację całej firmy ✅ Czym jest dashboard managera i dlaczego jest tak przydatny? • Video 2021"
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >Jesteś managerem i każdego dnia podejmujesz decyzje, od których zależy rozwój firmy? 
      Opierasz się na danych, czy masz kryształową kulę, która daje Ci właściwe odpowiedzi?
      Przeczytaj ten artykuł, a dowiesz się:
      </p>
      <p><ul>
      <li>Czym jest dashboard managera?</li>
      <li>Dlaczego menedżerowie chętnie z niego korzystają?</li>
      <li>Jak go przygotować nie tracąc na to całego dnia?</li>
      </ul></p>
      
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="WJTMIzEs71I"
          title="Czym jest dashboard managera, jak przyspieszyć z nim pracę i dlaczego inni kierownicy tak chętnie z niego korzystają?"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Sprawdź kluczowe wskaźniki efektywności
      </h2>
      <p>
        Dashboard menedżera to narzędzie, które pozwoli Ci szybko zapoznać się z kluczowymi wskaźnikami efektywności (KPI).
        Jak szybko? Najlepiej, gdy do oceny sytuacji wystarczy rzut oka na dane.  
      </p>
      <p>
     Gdyby np. szef sprzedaży miał przejrzeć kilkadziesiąt dokumentów sprzedażowych, czy przeprowadzić 
     rozmowę z każdym handlowcem w&nbsp;firmie - musiałby poświęcić wiele godzin na dobre rozeznanie.</p>
      <p>
        W XXI wieku nie ma czasu na marnotrawstwo. Jednym z najdroższych zasobów jest 
        czas, dlatego rozsądni menedżerowie starają się maksymalnie go wykorzystać, 
        używając nowoczesnych rozwiązań i optymalizując procesy.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz być na bieżąco z działaniami Twojej firmy?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać dasboard managera
            </Link>
          </div>
          </div>
    </section>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
       Jak powinien wyglądać dashboard managera?
      </h2>
      <p>
        Jak pewnie wiesz nie ma uniwersalnego rozwiązania. To co powinno 
        znaleźć się na desce rozdzielczej zależy od Ciebie, a właściwie od Twoich potrzeb. 
      </p>

      <p>
       Jesteś menedżerem sprzedaży? Z pewnością chcesz obserwować aktualne wyniki sprzedażowe.
       Czy to wystarczy? Gdy wszystko idzie zgodnie z&nbsp;planem to możliwe, że tak.
       Gdy pojawią się przeszkody, pomocne będą dodatkowe dane. Co&nbsp;na przykład?
      </p>
      <p><ul>
      <li><strong>Poziom sprzedaży wg handlowców lub&nbsp;realizacja celów </strong>- kto radzi sobie najlepiej a&nbsp;kto&nbsp;najgorzej? Może wózek 
      ciągnie jeden handlowiec lub przeciwnie - jeden odstaje od reszty i potrzebuje wsparcia.</li>
      <li><strong>Sprzedaż nie jest na odpowiednim poziomie? Cofnijmy się o krok </strong>- czy liczba zamówień, ofert i w końcu 
      samych zapytań od klientów jest wystarczająca, aby zapewnić zakładaną sprzedaż w danym okresie?</li>
      <li><strong>Zespół traci klientów, a wprowadzane usprawnienia są dalekie od rzeczywistych problemów? </strong>
      Może pomocne okaże się tutaj zestawienie przyczyn utraty potencjalnych klientów. </li>
      <li><strong>Nie wiesz czy handlowcy robią to co zaplanowaliście? </strong>Możesz szybko podejrzeć listę aktywności lub&nbsp;przełączyć się na widok 
      szans sprzedaży.</li>
      </ul>
      </p>
      <p><strong> Jeśli korzystasz z systemu, CRM lub innego rozwiązania informatycznego - na pewno zbierasz tam 
       dane, które są dla Twojej firmy, procesu i roli istotne i na tej podstawie zbudujesz zestaw kluczowych wskaźników.</strong>
      </p>
      <p>Jako, że CRM jest nam bliski, chcielibyśmy pokazać Ci dashboard managera w kilku wersjach, a&nbsp;następnie jak w kilka sekund zbudować takie narzędzie. 
      By zobaczyć jak powyższe przykłady działają w&nbsp;praktyce obejrzyj odcinek vloga, który
      znajduje się na początku tego artykułu.
</p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
       Dashboard managera sprzedaży - przykłady
      </h2>

<p>Dashboard managera to często jedna karta z kluczowymi parametrami. To czy powinna być to jedna czy wiele zakładek, zostawiamy do decyzji Tobie. Na naszym przykładzie jest to pulpit z wieloma zakładami - po to, abyśmy mogli pokazać Ci różne konfiguracje i możliwości. 
Tworząc własny pulpit - wybierz tylko te elementy, które uważasz u siebie za ważne. </p>

<p>Pierwsza zakładka przedstawia aktywności konkretnej osoby (kierownika):
<ul>
<li>kalendarz, </li>
<li>poziom realizacji moich celów - w tym sprzedaży,</li>
<li>zadania na dzień w formie listy - jeśli zadań jest bardzo dużo to ich przejrzenie na kalendarzu może sprawiać jakieś problemy, lista jest wtedy wygodniejsza. </li>
</ul>
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.aktywnosci]}
        alts={[
          "Dashboard managera - aktywności kierownika",
        ]}
      />
<p>Druga zakładka to aktywność handlowców:
<ul>
<li>cele wszystkich handlowców,</li>
<li>ofertowanie - procentowy udział handlowców,</li>
<li>typy wykonanych aktywności - np. spotkania,</li>
<li>ostatnie zdarzenia - co dziś zostało wykonane z notatką.</li>
</ul>
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.aktywnoscihandlowcow]}
        alts={[
          "Aktywnosci handlowcow - monitorowanie pracy zespołu sprzedaży",
        ]}
      />
<p>
Trzecia zakładka odnosi się do szans sprzedaży:
<ul>
<li>lejek szans sprzedaży - tutaj: jaka jest wartość szans na danych etapach, </li>
<li>przyczyny utraty szans sprzedaży - wraz z komentarzem handlowca,</li>
<li>konwersja lejka - wskazuje procentową konwersje szans między etapami.</li>
</ul>
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.szanse]}
        alts={[
          "Szanse sprzedaży raportowanie konwersja lejka",
        ]}
      />
<p>Kolejna zakładka to sprzedaż:
<ul>
<li>wartość sprzedaży w miesiącach,</li>
<li>sprzedaż wg kategorii,</li>
<li>sprzedaż wg PH,</li>
<li>ranking kontrahentów.</li>
</ul>
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.sprzedaz]}
        alts={[
          "Wyniki sprzedaży poziom realizacji sprzeday kpi",
        ]}
      />

<p>Ostatnia na którą chcemy zwrócić uwagę zawiera:
<ul>
<li>zdarzenia - czyli lista aktywności wykonanych przez handlowców. </li>
</ul>
</p>

<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdarzenia]}
        alts={[
          "monitorowanie działań handlowych - lista wykonanych zdarzeń",
        ]}
      />
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
       Jak stworzyć dashboard managera w kilka minut?
      </h2>
      <p>
       Załóżmy, że kilka z pokazanych przez Asię we&nbsp;vlogu raportów pasuje do Twoich potrzeb. Jak zbudować taki zestaw, pulpit menedżera w CRM?</p>
      <p> Zanim zaczniemy, jedna uwaga:<strong> tutaj powinny znaleźć się wyłącznie kluczowe wskaźniki</strong>. 
       Takie bez których nie jesteś w stanie ocenić czy zmierzacie w dobrym kierunku. 
       Wiemy, że pokusa jest duża, ale nie staraj się wrzucić tutaj wszystkich możliwości. 
       I tak z tego nie skorzystasz, będą Cię rozpraszać i zaciemniać pole widzenia. 
      </p>
     <p>
       Przechodzimy do zakładki w systemie CRM i&nbsp;wybieramy:
      </p>
      <p><ul>
      <li><strong>Dynamikę sprzedaży </strong>– jest to kluczowy wskaźnik. Sama wartość nic mi nie mówi jeśli nie widzę danych historycznych. </li>
      <li><strong>Listę zadań i kalendarz</strong>, aby nie pominąć zaplanowanych aktywności i spotkań.</li>
      <li><strong>Listę zdarzeń pracowników</strong>, aby obserwować ich działania i móc szybko zareagować.</li>
      <li><strong>Cele pracowników</strong>, aby obserwować ich indywidualne wyniki.</li>
      </ul>
      </p>
      <p><strong>Jaki powinien być pulpit menedżera?</strong>
      <li>Zawiera wyłącznie kluczowe informacje. </li>
      <li>Jest atrakcyjny wizualnie, spojrzenie na dane powinno wystarczyć, aby zapoznać się z&nbsp;sytuacją.</li>
      <li>Dobrze, jeśli jest szybki do utworzenia i łatwy do modyfikacji. </li></p>
<h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {" "}
       Podsumowując...
      </h2>
      <p>
      Mamy nadzieję, że ten artykuł przybliżył Ci działanie pulpitu menedżera. Nie znajdziesz stałego 
      wzoru na jego utworzenie. Dashboard  managera powinien być dopasowany do Twoich potrzeb. </p>
      <p>Jeśli chcesz 
      wypróbować CRM w swojej firmie, skontaktuj się z nami, chętnie pomożemy Ci dopasować ten i&nbsp;inne widoki do Twoich potrzeb.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz być na bieżąco z działaniami Twojej firmy?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać dasboard managera
            </Link>
          </div>
          </div>
    </section>
      <p>
        Polecamy również:
        <ul>
          <li>
            <Link to="/zastepowalnosc-pracownikow/"> Zastępowalność pracowników, czyli jak zapewnić ciągłość pracy?</Link>
          </li>
          <li>
            <Link to="/raportowanie-sprzedazy/">
              {" "}
             16 przykładów raportowania sprzedaży w firmie{" "}
            </Link>
          </li>
          <li>
            <Link to="/zalety-elastycznego-crm/">
              {" "}
              Zalety elastycznego systemu CRM{" "}
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default DashboardManagera;
